<template>
  <van-popup v-model="popShow" position="bottom" class="popup">
    <div class="list">
      <div class="list_item" v-for="item, index in list" :key="index" @click="submit(item,index)">
        <slot name="title" :item="item" :index="index">
          {{ item[valueKey] }}
        </slot>
      </div>
      <div class="cancel" @click="close">{{ $t('My.Button.One') }}</div>
    </div>
  </van-popup>
</template>
<script>
import { Popup } from 'vant';
export default {
  components: {
    [Popup.name]: Popup,
  },
  props: {
    list: {
      type: Object | Array,
      default: []
    },
    valueKey: {
      type: String,
      default: 'name'
    }
  },
  data() {
    return {
      popShow: false
    }
  },
  methods: {
    open() {
      this.popShow = true;
    },
    close() {
      this.popShow = false;
    },
    submit(item,index) {
      this.$emit('submit', {item,index})
    }
  }
}
</script>
<style lang="less" scoped>
.popup {
  background-color: transparent;
}

.list {
  &_item {
    width: 98%;
    background-color: var(--light);
    margin: 0 auto;
    height: calc(60rem / 16);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: calc(2rem / 16);
    color: #0e90d2;
    font-size: calc(14rem / 16);
  }

  .cancel {
    width: 98%;
    margin: calc(5rem / 16) auto;
    text-align: center;
    background: linear-gradient(179deg, #13a2ba, #087c95);
    color: var(--light);
    height: calc(40rem / 16);
    font-size: calc(12rem / 16);
    line-height: calc(40rem / 16);
  }
}
</style>